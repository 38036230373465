<template>
    <modal ref="modalFinalizarServicio" tamano="modal-lg" titulo="Finalizar servicio" adicional="Finalizar" @adicional="finalizarServicios">
        <ValidationObserver ref="validator">
            <div class="row justify-content-center m-3 f-15">
                <div class="col-6 my-2">
                    <p class="input-label-top">Fecha de inicio</p>
                    <el-date-picker v-model="fechaInicio" type="date" class="w-100" size="small" disabled />
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider v-slot="{ errors }" name="fecha fin" rules="required">
                        <p class="input-label-top">Fecha de finalización</p>
                        <el-date-picker v-model="fechaFinal" type="date" class="w-100" size="small" format="yyyy-MM-dd"  value-format="yyyy-MM-dd" placeholder="Seleccionar un día" />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
                <div class="col-12 my-2">
                    <ValidationProvider v-slot="{ errors }" name="comentario" rules="required">
                        <p class="input-label-top">Comentario del servicio</p>
                        <el-input v-model="comentarioServicio" type="textarea" :autosize="{ minRows: 4, maxRows: 4}" class="w-100" size="small" />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            valueCalificacion: null,
            fechaInicio: '',
            fechaFinal: '',
            comentarioServicio: '',
            taller:''
        }
    },
    methods: {
        toggle(data){
            this.$refs.validator.reset()
            this.clean()
            if (data) {
                this.fechaInicio = data.fecha_inicio
                this.taller = data.taller
            }
            this.$refs.modalFinalizarServicio.toggle()
        },
        async finalizarServicios(){
            const valid = await this.$refs.validator.validate()
            if (!valid) return
            this.$emit('calificar',{
                fecha_inicio: this.fechaInicio, 
                fecha_final: this.fechaFinal, 
                comentario: this.comentarioServicio,
                taller: this.taller
            })
            this.$refs.modalFinalizarServicio.toggle()
            this.clean()
        },
        clean(){
            this.fechaInicio = ''
            this.fechaFinal = ''
            this.comentarioServicio = ''
        }
    }
}
</script>

<style lang="css" scoped>
</style>
